








import { PayInfos } from 'piramis-base-components/src/components/ServicesCart/logic/types';
import ServicesCart from 'piramis-base-components/src/plugins/AdminTemplate/pages/services-cart.vue'

import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    ServicesCart,
  }
})
export default class LicenseBuy extends Vue {
  payMethods = this.defaultPayMethods()
  payInfo: PayInfos | null = null

  updatePayInfo(data: PayInfos) {
    this.payInfo = data
  }

  defaultPayMethods() {
    return {
      targetIdKey: 'user_key',
      config: {
        target_id: {
          user_key: this.$store.getters.getCurrentUser(this.$route.params.id).key
        },
        target_type: 'user'
      }
    }
  }

}
